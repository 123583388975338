.gradientBox {
  position: relative;
  border-radius: 10px;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    margin: -1px;
    border-radius: 10px;
    background: linear-gradient(to bottom right, #74b74b, #2e96e6);
  }
}
